<template>
  <Breadcrumb :home="breadcrumb.home" :model="breadcrumb.items" style="padding-top:0px;background: none;border: none;"/>
  <div class="card">
    <Toolbar class="p-mb-4" style="background-color: #fff;border:none;padding-bottom:0px;">
      <template #right>
        <div class="p-inputgroup">
          <InputText @keypress="$event.keyCode==13?query():{}" style="width:200px;" v-model="dataTable.queryParams.name"
                     placeholder="请输入项目名称"/>
          <Button @click="query()" icon="pi pi-search" style="background: #FFFFFF;color: #000000;opacity: 0.25;"/>
        </div>
      </template>
    </Toolbar>

    <DataTable :scrollable="true" :rowHover="true" :value="dataTable.gridData" :lazy="true" :loading="dataTable.loading"
               @page="onPage($event)" :totalRecords="dataTable.totalRecords"
               responsiveLayout="scroll" :paginator="true" :rows="10"
               paginatorTemplate="PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport"
               :rowsPerPageOptions="[10,20,50,100]"
               currentPageReportTemplate="共 {totalRecords} 条">
      <template #empty>
        没有发现数据。
      </template>
      <Column field="code" header="项目编号" style="min-width:12rem"></Column>
      <Column field="name" header="项目名称" style="min-width:20rem"></Column>
      <Column field="userlist" header="项目成员" style="min-width:16rem">
        <template #body="slotProps">
          <label v-bind:key="item" v-for="(item, index) of slotProps.data.showusers">
            <span v-if="item.is_leader == 1">
              <i v-tooltip="'组长'" class="pi pi-user"
                 style="color:rgba(82, 196, 26, 1);border-radius: 50px;border:1px solid rgba(183, 235, 143, 1);font-size:8px;background-color: rgba(246, 255, 237, 1);padding:2px;"></i>
              {{ item.name }}
            </span>
            <span v-else>
              {{ item.name }}
            </span>
            <span v-if="index &lt; slotProps.data.showusers.length-1">，</span>
          </label>
          <span style="cursor:pointer;" v-if="slotProps.data.hideusers" v-tooltip="slotProps.data.hideusers"> ...</span>
        </template>
      </Column>
      <Column field="archiveType" header="归档类型" style="min-width:10rem">
        <template #body="slotProps">
          <label v-if="slotProps.data.archiveType == 'year'">按年</label>
          <label v-if="slotProps.data.archiveType == 'season'">按季度</label>
          <label v-if="slotProps.data.archiveType == 'other'">其他</label>
        </template>
      </Column>
      <Column field="location" header="项目地点" style="min-width:20rem"></Column>
      <Column field="position" header="坐标" style="min-width:23rem"></Column>
      <Column field="type" header="项目类型" style="min-width:10rem"></Column>
      <Column field="aim" header="目标" style="min-width:20rem"></Column>
      <Column :exportable="false" header="操作" frozen alignFrozen="right" style="min-width:10rem">
        <template #body="slotProps">
          <a href="javascript:" class="a-button" @click="view(slotProps.data)">查看</a>
        </template>
      </Column>
      <template #paginatorLeft></template>
      <template #paginatorRight>
        <div class="p-field">
          <label>跳至&nbsp;&nbsp;</label>
          <InputNumber @keypress="jumpPage()" v-tooltip.focus="'按下回车键后跳转'" inputStyle="width: 40px;" id="integeronly"
                       v-model="dataTable.jumpPageNum"/>
          <label>&nbsp;&nbsp;页</label>
        </div>
      </template>
    </DataTable>

    <ConfirmDialog></ConfirmDialog>
    <Toast/>

  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      form: {
        id: null,
        name: null,
        telephone: null,
        role: null,
        email: null,
        team: null,
        $submitted: false,
        $formMode: "create",
      },
      breadcrumb: {
        home: { label: "种植项目", to: "#" },
        items: [
          { label: "项目管理", to: "#" },
          { label: "归档的项目", to: "#" },
        ],
      },
      dataTable: {
        gridData: [],
        queryParams: {
          name: null
        },
        lazyParams: {
          page: 0
        },
        loading: true,
        totalRecords: 0,
        jumpPageNum: 1,
        lastParams: null
      },
      year: null
    }
  },
  watch: {
    '$route'() {
      // console.log(this.$route)
      if (this.$route.fullPath.indexOf("/project/archive") != -1) {
        this.year = this.$route.query.year
        this.loadTable()
      }
    }
  },
  mounted() {
    this.year = this.$route.query.year
    this.loadTable()
  },
  methods: {
    jumpPage() {
      if (this.dataTable.jumpPageNum > 0 && event.keyCode == 13) {
        this.dataTable.lazyParams.page = this.dataTable.jumpPageNum - 1;
        this.loadTable(this.dataTable.lastParams);
      }
    },

    query() {
      if (this.dataTable.queryParams.name) {
        this.loadTable({
          "name": this.dataTable.queryParams.name
        });
      } else {
        this.loadTable();
      }
    },

    /**
     * 加载表格数据
     */
    loadTable(params) {
      let me = this;
      me.loading = true;
      this.dataTable.lastParams = params;
      let targetParmas = {
        "current": this.dataTable.lazyParams.page + 1,
        "size": this.dataTable.lazyParams.rows,
        "year": this.year
      };
      if (params) {
        Object.assign(targetParmas, params);
      }
      this.$http('/project/list/archive', 'get', targetParmas, function (response) {
        me.dataTable.gridData = response.data.records
        console.log(me.dataTable.gridData);
        // 将组长排在第一位
        for (let i in me.dataTable.gridData) {
          let row = me.dataTable.gridData[i];
          let userlist = JSON.parse(row.userlist);
          if (userlist) {
            userlist.sort((a) => {
              if (a.is_leader == 1) {
                return -1;
              } else {
                return 0;
              }
            });
            row.userlist = userlist;
            // 只显示前3个用户
            row.showusers = JSON.parse(JSON.stringify(userlist)).slice(0, 3);
            // 将之后的几个用户单独存起来
            if (userlist.length > 3) {
              let hideusers = JSON.parse(JSON.stringify(userlist)).slice(3)
              let str = ""
              for (let i in hideusers) {
                str += hideusers[i].name + "，";
              }
              str = str.substring(0, str.length - 1)
              row.hideusers = str;
            }
          }
        }
        me.dataTable.totalRecords = response.data.total;
        me.dataTable.loading = false;
      })
    },

    onPage(event) {
      this.dataTable.lazyParams = event;
      this.loadTable(this.dataTable.lastParams);
    },

    view(row) {
      this.$router.push({path: '/archive/view', query: {action: 'view', id: row.id}});
    }
  }
}
</script>

<style lang="scss" scoped>
.a-button {
  color: #12A25D;
  font-size: 14px;
  margin-left: 8px;
  margin-right: 8px;
}

.admin-status {
  width: 56px;
  height: 22px;
  border: 1px double #91D5FF;
  color: #1890FF;
  font-size: 12px;
  padding: 4px 8px 4px 8px;
  background-color: #E6F7FF;
}

.user-status {
  width: 56px;
  height: 22px;
  border: 1px double #B7EB8F;
  color: #52C41A;
  font-size: 12px;
  padding: 4px 8px 4px 8px;
  background-color: #F6FFED;
}

//去掉表格底部的线条
::v-deep(.p-paginator-bottom) {
  border-bottom: none !important;
}

::v-deep(.p-paginator .p-paginator-pages .p-paginator-page.p-highlight) {
  background: #12A25D;
  border-color: #12A25D;
  color: #FFFFFF;
}
</style>
